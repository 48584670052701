<template lang="pug">
  .p-404
    .error-title 404
    .error-description {{ $t("404.description") }}
    router-link(:to="{name: 'home'}") {{ $t("404.link") }}
</template>

<script>
export default {
  name: 'Page404'
}
</script>

